<template>
  <button v-if="type === 'print'" :class="customClass" @click="print">
    <font-awesome-icon :icon="iconForButton"></font-awesome-icon>&nbsp;
    <slot name="default">Print {{ name }}</slot>
  </button>
  <a
    v-else-if="type === 'download'"
    :href="url"
    :download="name"
    :class="customClass"
  >
    <font-awesome-icon :icon="iconForButton"></font-awesome-icon>&nbsp;
    <slot name="default">Download {{ name }}</slot>
  </a>
  <a v-else :href="url" :class="customClass">
    <font-awesome-icon :icon="iconForButton"></font-awesome-icon>&nbsp;
    <slot name="default"> Download {{ name }}</slot>
  </a>
</template>

<script>
import printJS from 'print-js'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'PdfButton',
  props: {
    type: {
      type: String,
      default: 'print',
      required: false
    },
    icon: {
      type: String,
      default: null,
      required: false
    },
    url: {
      type: String,
      required: true
    },
    showModal: {
      type: Boolean,
      default: false,
      required: false
    },
    modalMessage: {
      type: String,
      default: 'Retrieving File for printing...',
      required: false
    },
    fileName: {
      type: String,
      required: false
    },
    customClass: {
      type: String,
      default: 'btn btn-info btn-block'
    }
  },
  computed: {
    name () {
      if (!isEmpty(this.fileName)) {
        return this.fileName
      } else {
        return ''
      }
    },
    iconForButton () {
      if (!isEmpty(this.icon)) {
        return this.icon
      }
      switch (this.type) {
        case 'print':
          return 'print'
        case 'download':
          return 'download'
        default:
          return 'pdf-file'
      }
    }
  },
  methods: {
    print () {
      const vm = this
      printJS({
        printable: this.url,
        type: 'pdf',
        showModal: this.showModal,
        modalMessage: this.modalMessage,
        onError: function (error) {
          vm.$notify({
            group: 'enrollment',
            type: 'error',
            title: 'problem printing',
            text: error,
            duration: 2000,
            speed: 1000
          })
        }
      })
    }
  }
}
</script>

<style scoped></style>
